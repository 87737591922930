.Main {
  font-family: var(--title-font);
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  padding: var(--space-large) var(--space-small);
  background: white;
  gap: var(--space-medium);
}

.Date,
.Contact,
.Tickets {
  text-transform: uppercase;
}

.Tickets {
  padding-bottom: var(--space-medium);
}

.Calendar {
  font-family: var(--main-font);
  font-size: 1rem;
  line-height: 1;
  padding-bottom: var(--space-medium);
}

@media (min-width: 1024px) {
  .Main {
    grid-template-columns: repeat(2, minmax(0, 50%));
    padding: var(--space-large);
  }

  .Tickets {
    order: 2;
  }

  .Address {
    order: 3;
  }
}
