@font-face {
  font-family: 'Whyte Inktrap Book';
  src: url('../src/assets/fonts/ABCWhyteInktrap-Book.woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Whyte Inktrap Extralight';
  src: url('../src/assets/fonts/ABCWhyteInktrap-Extralight.woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

:root {
  --main-font: 'Whyte Inktrap Book';
  --title-font: 'Whyte Inktrap Extralight';

  --title-size: 11vw;

  --space-large: 25vw;
  --space-medium: 12vw;
  --space-small: 5vw;

  --ease-inout-sine: cubic-bezier(0.37, 0, 0.63, 1);
  --ease-in-cubic: cubic-bezier(0.32, 0, 0.67, 0);
  --ease-out-cubic: cubic-bezier(0.33, 1, 0.68, 1);
  --ease-inout-cubic: cubic-bezier(0.65, 0, 0.35, 1);
  --ease-in-expo: cubic-bezier(0.95, 0.05, 0.795, 0.035);
  --ease-out-expo: cubic-bezier(0.19, 1, 0.22, 1);
  --ease-inout-expo: cubic-bezier(0.87, 0, 0.13, 1);
  --ease-out-back: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  --ease-inout-back: cubic-bezier(0.68, -0.6, 0.32, 1.6);

  --easing: cubic-bezier(0.18, 0.95, 0.7, 1);
  --basic-transition: opacity 0.5s var(--timeline), transform 0.5s var(--timeline);
  --placeholder-color: #f5f5f5;

  --accent-red: #f6371e;
  --background-green: #265f5f;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-weight: inherit;
  font-size: inherit;
}

p {
  margin: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

button {
  color: inherit;
  font: inherit;
  text-align: left;
  background: none;
  padding: 0;
  border: 0;
  cursor: pointer;
  text-transform: inherit;
  letter-spacing: inherit;
}

@media (min-width: 1024px) {
  :root {
    --space-large: 10vw;
    --space-medium: 4vw;
    --space-small: 2vw;
    --title-size: 4vw;
  }
}

@media (min-width: 1440px) {
  :root {
    --title-size: 3.5vw;
  }
}
