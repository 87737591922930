.Main {
  position: relative;
  display: block;
  width: fit-content;
  font-family: var(--title-font);
  font-size: var(--title-size);
  line-height: 1;
  overflow: hidden;
}

.Inner {
  position: relative;
  display: block;
  transition: transform 400ms var(--ease-inout-cubic);
}

.Main::after {
  content: '<-';
  position: absolute;
  top: 0;
  left: 100%;
  height: 100%;
  width: 100%;
  transform: translateX(100%);
  transition: transform 400ms var(--ease-inout-cubic);
}

.Main:hover .Inner {
  transform: translateX(-100%);
}

.Main:hover::after {
  transform: translateX(-100%);
}
