.Main {
  padding: var(--space-large) var(--space-small);
  display: grid;
  grid-template-columns: minmax(0, 60vh);
  justify-content: center;
  overflow: hidden;
}

.Main h1 {
  font-family: var(--title-font);
  font-size: var(--title-size);
  text-transform: uppercase;
  line-height: 1;
  padding-bottom: 1em;
}
